const md5 = require("md5");
const sha1 = require("sha1");

export default class Application {
    constructor(args) {
        try {
            // Copy everything given to the constructor; storage and api are expected
            // Storage will be sent along so the API can properly initialize
            for (let prop in args) {
                this[prop] = args[prop];
            }

            // Get stuff from config
            this.name = config.VUE_CONFIG_APP_NAME;
            this.version = 'v' + config.VUE_CONFIG_APP_VERSION;
            this.latestVersion = '-';

            // Get stuff from the local storage
            this.properties = ['user', 'tables.users'];
            for (let prop of this.properties) {
                this[prop] = this.storage.get(prop);
            }

            // When closing a form, we would like to go back to either the list or the drawing, so remember this: list or drawing
            this.formOpener = 'list';

            // These are the timer used to control the timeout before the upload function is called again
            this.uploadTimerFast = 200;
            this.uploadTimerSlow = 4000;

            // When loading a form to read a record, initForm will pickup the values from this global
            // If null, the form is for editing; if 
            this.record = null;
        } catch (error) {
            console.error(error);
        }
    }

    initialize() {
        console.log('app.initialize()');
        let self = this;

        // Initialization of the API may take some time as it may want to login at the server, so make it a promise
        return new Promise(function (resolve, reject) {
            try {
                self.api.initialize(self.storage);

                // Initialize the console (don't post logs, do post errors)
                if (self.console) {
                    if (window.location.host != 'localhost:8080' && self.api.server) {
                        self.console.initialize(false, true, 'https://' + self.api.server + self.api.server_path + 'console');
                    }
                }

                // Get the latest available version of the app
                self.api.getLatestVersion().then( version => {
                    self.latestVersion = version;
                });

                // Start the uploader - let it call itself when done
                if (self.storage.get('app.uploadAutomatic') === null) {
                    self.storage.set('app.uploadAutomatic', true);
                }
                self.upload( true );

                resolve();
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }

    server() {
        return (window.location.href + '#').split('#')[0]
    }

    isMobile() {
        return (/iPhone|iPad|iPod|Android|Opera\sMini|Windows\sPhone/i.test(navigator.userAgent));
    }

    isOnline() {
        let self = this;
        return self.api.isOnline;
    }

    isStandalone() {
        // https://stackoverflow.com/a/51735941/4177565
        return (window.matchMedia('(display-mode: standalone)').matches);
    }

    GMTToLocal(GMTTime = null) {
        var date = GMTTime ? new Date(GMTTime) : new Date();
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 19).replace('T', ' ');
    }

    getGPS(callback) {
        console.log('app.getGPS(<callback>)');

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    callback(position);
                },
                (error) => {
                    console.error(error);
                    callback(null);
                }, {
                maximumAge: 0,
                timeout: 5000,
                enableHighAccuracy: true
            }
            );
        } else {
            callback(null);
        }
    }

    generateUUID() {
        // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
        if (crypto) {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        } else {
            let d = new Date().getTime();
            let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16;
                if (d > 0) { // Use timestamp until depleted
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else { // Use microseconds since page-load (if supported)
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }
    }

    escapeHTML(html) {
        //return String(html).replaceAll('<', '&lt;').replaceAll('>', '&gt;');
        let result = new String(html);
        result = html.replace(new RegExp('<', 'g'), '&lt;');
        result = html.replace(new RegExp('>', 'g'), '&gt;');
        return result;
    }

    parseNumber(value) {
        // Parse value as float, but replace the european comma with a dot first
        if (value.trim() == '') {
            return 0;
        }
        // return parseFloat(value.trim().replaceAll(' ', '').replaceAll(', ', '.'));
        let result = new String(value.trim());
        result = result.replace(new RegExp(' ', 'g'), '');
        result = result.replace(new RegExp(', ', 'g'), '.');
        return parseFloat(result);
    }

    alert(message, title = '') {
        console.log('app.alert(' + message + ',' + title + ')');
        let self = this;

        // Load the title and text
        document.getElementById('infoModalLabel').innerText = title || self.name;
        document.getElementById('infoModalDescription').innerText = message;

        // Hide the Cancel button, prepare the OK button
        document.getElementById('infoModalCancel').hidden = true;
        document.getElementById('infoModalOK').hidden = false;
        document.getElementById('infoModalOK').removeAttribute('onclick');
        // Show the modal with the overview
        document.getElementById('infoModal')._modal.show();
    }

    confirm(message, title = '', cbOK = null, cbCancel = null) {
        console.log('app.confirm(' + message + ',' + title + ',<cbOK>,<cbCancel>)');
        let self = this;

        // Load the title and text
        document.getElementById('infoModalLabel').innerText = title || self.name;
        document.getElementById('infoModalDescription').innerText = message;

        // Show the Cancel and OK buttons, prepare them 
        document.getElementById('infoModalCancel').hidden = false;
        document.getElementById('infoModalCancel').onclick = function () {
            // Reset the onclick itself so it won't happen next time
            document.getElementById('infoModalCancel').removeAttribute('onclick');
            // Close the popup
            self.popupInfoClose();
            // Do what needs to be done, if there is anything
            if (cbCancel) { cbCancel() }
        }
        document.getElementById('infoModalOK').hidden = false;
        document.getElementById('infoModalOK').onclick = function () {
            // Reset the onclick itself so it won't happen next time
            document.getElementById('infoModalOK').removeAttribute('onclick');
            // Close the popup
            self.popupInfoClose();
            // Do what needs to be done, if there is anything
            if (cbOK) { cbOK() }
        }

        // Show the modal with the overview
        document.getElementById('infoModal')._modal.show();
    }

    login(server = '', username = '', password = '', forceServer = false) {
        console.log('app.login(' + server + ',' + username + ',<password>,' + forceServer + ')');
        let self = this;

        if (self.storage.has('info.user') && !forceServer && !self.isOnline()) {
            return new Promise(function (resolve, reject) {
                console.log('Logging in locally');
                let users = self.storage.get('info.user');
                let password_hash = sha1(md5(password));
                for (let u in users) {
                    if (users[u].username == username && users[u].password == password_hash) {
                        // The token is set to something not empty, so this will allow the user to use the app locally
                        // But before uploading, the user will have to authenticate
                        self.user = {
                            'id': users[u].id,
                            'token': 'local-login',
                            'username': users[u].username,
                        };
                        self.storage.set('user', self.user);
                        resolve(true);
                    }
                }
                reject();
            });
        } else {
            return new Promise(function (resolve, reject) {
                console.log('Logging in remotely');
                self.api.login(server, username, password)
                    .then(result => {
                        self.user = self.storage.set('user', result);
                        resolve(true);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }

    logout() {
        console.log('app.logout()');
        let self = this;

        try {
            // Remove the token and store the new user object
            delete self.user.token;
            self.user = self.storage.set('user', self.user);
            return true;
        } catch (error) {
            console.error(error);
            return true;
        }
    }

    isDrawingLoaded(id = 'container_drawing') {
        let el = document.getElementById(id);
        return (!el.hidden);
    }

    executeScripts(containerElement) {
        // This is used by loadForm
        // https://stackoverflow.com/a/69190644/4177565
        const scriptElements = containerElement.querySelectorAll("script");

        Array.from(scriptElements).forEach((scriptElement) => {
            const clonedElement = document.createElement("script");

            Array.from(scriptElement.attributes).forEach((attribute) => {
                clonedElement.setAttribute(attribute.name, attribute.value);
            });

            clonedElement.text = scriptElement.text;

            scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
        });
    }

    loadForm(name, record = null) {
        console.log('app.loadForm(' + name + ',<record>)');
        let self = this;

        // Close the info modal
        self.popupInfoClose();

        // Set the record to be picked up by initForm
        // We're settings this as a global because this app does not control how the forms deal with this
        // We expect the form to call loadInfo at some point, where we will pickup this again
        self.record = record;

        // Hide the vue and drawing containers, load the app container and show it
        document.getElementById('container_vue').hidden = true;
        document.getElementById('container_drawing').hidden = true;
        document.getElementById('container_drawing_info').hidden = true;

        // Figure out the html to load
        let html = self.storage.get('forms.' + name + '.html');
        if (html) {
            if (record && record.id) {
                // A record exists, so we load the form in readonly mode
                html = '<fieldset disabled="disabled" data-record-id="' + record.id + '">' + html + '</fieldset>';
            } else {
                // No record exists yet, so we load the form in edit mode
                html = '<fieldset>' + html + '</fieldset>';
            }
        } else {
            // The close button will not ask confirmation before unloading the form
            html = 'Denne filen mangler. Vennligst synkroniser først.';
        }
        let el = document.getElementById('container_app');
        el.innerHTML = '<button type="button" class="btn-close float-end" aria-label="Close" style="position:absolute;top:4.5rem;right:1rem" onclick="application.unloadForm(' + (record ? 'true' : '') + ')"></button>' + html;

        self.executeScripts(el);
        el.hidden = false;
    }

    initForm(definitions) {
        // Loads the options for selects
        console.log('app.initForm(<definitions>)');
        let self = this;

        return new Promise(function (resolve, reject) {
            try {
                // Set the tom-select selects
                let tomSelectDefault = {
                    maxItems: 1,
                    valueField: 'id',
                    labelField: 'value',
                    selectOnTab: true,
                    render: {
                        option: function (data, escape) {
                            let result = '';
                            for (let key in data) {
                                if (key.substring(0, 1) != '$' && key != 'id') {
                                    result += `<p class="${key}">` + escape(data[key]) + '</p>';
                                }
                            }
                            return '<div>' + result + '</div>';
                        },
                        item: function (data, escape) {
                            return '<div title="' + escape(data.id) + '">' + escape(data.value) + '</div>';
                        },
                        option_create: function (data, escape) {
                            return '<div class="create">Legg til <strong>' + escape(data.input) + '</strong>&hellip;</div>';
                        },
                        no_results: function () {
                            return '<div class="no-results">Ikke funnet.</div>';
                        },
                    }
                };

                document.querySelectorAll('select.tom-select')
                    .forEach((el) => {
                        if (definitions[el.id]) {
                            let tomElementAttr = {};
                            if (el.nodeName.toLowerCase() == 'select') {
                                tomElementAttr = { ...tomSelectDefault };
                            }
                            tomElementAttr.options = self.storage.get('info.' + definitions[el.id]);
                            if (el.dataset.tomselect) {
                                let newAttrs = JSON.parse(el.dataset.tomselect);
                                for (let key in newAttrs) {
                                    tomElementAttr[key] = newAttrs[key];
                                }
                            }
                            // Attach it to the HTML Element as well, so we can retrieve it later
                            // TomSelect is added to the element so we can access it as el.tomselect later
                            // Todo: Implement this later when the forms are all corrected! (2022-09-27)
                            el._TomSelect = new window.TomSelect(el, tomElementAttr);
                            //new window.TomSelect(el, tomElementAttr);
                        }
                    });

                // Set the other (non-tom-select) selects
                document.querySelectorAll('select:not(.tom-select)')
                    .forEach((el) => {
                        if (definitions[el.id]) {
                            let choices = self.storage.get('info.' + definitions[el.id]);
                            for (let key in choices) {
                                // After adding the id and value, remove them from choices, so that looping any remaining properties goes faster
                                let option = new Option(choices[key].value, choices[key].id);
                                delete choices[key].id;
                                delete choices[key].value;

                                for (let key2 in choices[key]) {
                                    if (key2 != 'id' && key2 != 'value') {
                                        option.setAttribute('data-' + key2, choices[key][key2]);
                                    }
                                }
                                el.options.add(option);
                            }
                        }
                    });
                resolve();
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }

    unloadForm(skipConfirm = false) {
        // Unload the html, hide the element
        console.log('app.unloadForm(' + skipConfirm + ')');
        let self = this;

        function closeForm() {
            // Unload the form, hide the element
            let el = document.getElementById('container_app');
            el.innerHTML = '';
            el.hidden = true;

            // Forms no longer should pickup the drawing reference (the drawingName itself is kept, as it is used by drawing scripts)
            self.drawing.drawingReference = '';

            // Based on where the user came from (the list or the drawing), go back there
            switch (self.formOpener) {
                case 'drawing':
                    document.getElementById('container_drawing').hidden = false;
                    document.getElementById('container_drawing_info').hidden = false;
                    break;
                case 'list':
                    document.getElementById('container_vue').hidden = false;
                    break;
                default:
                    document.getElementById('container_vue').hidden = false;
                    console.error('Unknown value for self.formOpener: ' + self.formOpener + '; expected "list" or "drawing"');
                    break;
            }
        }

        if (skipConfirm) {
            closeForm();
        } else {
            self.confirm('Vil du avbryte registreringen?', null, () => { closeForm() });
        }
    }

    resetRemembered( form_id = 'form_pyrodok' ) {
        try {
            let rememberedElements = [];
            let skipElements = ['fk_project'];
            let form = document.getElementById(form_id);
            form.querySelectorAll('[data-properties]').forEach((el) => {
                let properties = JSON.parse(el.dataset.properties);
                if( properties.remember ) {
                    if( skipElements.indexOf(el.name) == -1 ) {
                        rememberedElements.push(el);
                    }
                }
            });
            rememberedElements.forEach( el => {
                switch( el.tagName.toLowerCase()) {
                case 'textarea':
                    el.value = '';
                    break;
                case 'select':
                    el.selectedIndex = -1;
                    break;
                case 'input':
                    switch( el.type.toLowerCase()) {
                        case 'checkbox':
                        case 'radio':
                            el.checked = false;
                            break;
                        default:
                            el.value = '';
                            break;
                    }
                    break;
                default:
                    // Unknown
                    break;
                }
            });
        } catch( error ) {
            console.log( error );
        }
    }

    loadInfo(info) {
        console.log('app.loadInfo(<info>)');
        let self = this;

        // Variable info gives us *some* values (e.g. GPS values), so add these to remembered values
        // But if we're viewing an existing record (available in class variable record), then that overrules everything
        if (self.record) {
            info = self.record.data;
        } else {
            // Get the remembered values, add these to info if they're not already set there
            let remember = self.storage.get('app.remember');
            for (let remember_item in remember) {
                if (!info[remember_item]) {
                    info[remember_item] = remember[remember_item];
                }
            }

            // If we are coming from a drawing, set the reference - this value overwrite anything set earlier
            if (self.drawing.drawingReference) {
                info.reference = self.drawing.drawingReference;
            }
        }

        // Now set the input values
        return new Promise(function (resolve, reject) {
            try {
                for (let i in info) {
                    var el = document.getElementsByName(i)[0];
                    if (el) {
                        // Split multiple values, based on ","
                        if (el.type == 'select' || el.type == 'checkbox') {
                            // Split values - if no "," then this gives an array with one value
                            if (typeof info[i] !== 'object') {
                                info[i] = info[i].split(',');
                            }
                        }

                        // Set the value, based on the element type
                        if (el._TomSelect) {
                            el._TomSelect.addItem(info[i]);
                            el._TomSelect.setValue(info[i], true);
                            if (el.value == '') {
                                el._TomSelect.addOption({ id: info[i], value: info[i] });
                                el._TomSelect.setValue(info[i]);
                            }
                        } else if (el.type.toLowerCase() == 'radio') {
                            // Radio, set the single value
                            // Check the value first, it may not be set and THAT radio variant does not exist...
                            if (info[i] != '') {
                                document.querySelector('input[name="' + el.name + '"][value="' + info[i] + '"]').checked = true
                            }
                        } else if (el.type.toLowerCase() == 'checkbox') {
                            // Checkbox, set (optionally) multiple values
                            for (let val of info[i]) {
                                // Check the value first, it may not be set and THAT checkbox variant does not exist...
                                if (val != '') {
                                    document.querySelector('input[name="' + el.name + '"][value="' + val + '"]').checked = true
                                }
                            }
                        } else {
                            el.value = info[i];
                        }
                    } else {
                        console.log('Missing element with name ' + i);
                    }
                }
                resolve();
            } catch (error) {
                console.error(error);
                reject();
            }
        });
    }

    rememberFieldValues(values) {
        // Called before the form is saved to remember the default field values
        console.log('app.rememberFieldValues(<values>)');
        let self = this;

        // Loop through the html elements, check for data-remember=true
        let remember = self.storage.get('app.remember') || {};

        let hasChanged = false;
        for (let id in values) {
            let el = document.getElementById(id);
            if (el) {
                if (el.hasAttribute('data-properties')) {
                    let properties = {};
                    try {
                        properties = JSON.parse(el.getAttribute('data-properties'));
                    } catch (error) {
                        console.error('Could not parse properties of ' + el.id);
                    }

                    if (properties.remember) {
                        remember[id] = values[id];
                        hasChanged = true;
                    }
                }
            }
        }

        if (hasChanged) {
            self.storage.set('app.remember', remember);
        }
    }

    getFields(form, type = 'store') {
        // Returns a set of fields, where type is either:
        // * store: all that are to be stored
        // * show: all that are to be shown in a list
        // * missing: that are required, but lack values
        console.log('app.getFields(<form>,' + type + ')');

        let result = {};
        try {
            form.querySelectorAll('[data-properties]').forEach((el) => {
                let properties = JSON.parse(el.dataset.properties);
                let value = '';

                // Figure out the element type - if it's a text input, get the text type value
                let node_type = el.nodeName.toLowerCase();
                if (node_type == 'input') {
                    node_type = el.type.toLowerCase();
                }

                // Get the value(s)
                switch (node_type) {
                    case 'select':
                        // This will work for both multi and single selects
                        value = [...document.querySelectorAll('#' + el.id + ' :checked')].map(option => option.value.trim()).join(',')
                        break;
                    case 'checkbox':
                        value = [...document.querySelectorAll('[name="' + el.name + '"]:checked')].map(el => el.value.trim()).join(',');
                        break;
                    case 'radio':
                        value = document.querySelector('[name="' + el.name + '"]:checked').value.trim();
                        break;
                    default:
                        value = el.value.trim();
                }

                // What is it we need to return
                switch (type) {
                    case 'store':
                        // This is the id/value pairs that we'll use to store
                        if (properties.store) {
                            result[el.name] = value;
                        }
                        break;
                    case 'show':
                        // This takes the field value, combined with the label specified in the properties, as we'll show the list
                        if (properties.show) {
                            if (result[properties.label] && result[properties.label] != '') {
                                value = result[properties.label] + ', ' + value;
                            }
                            result[properties.label] = value;
                        }
                        break;
                    case 'missing':
                        // This will use the field label (value is not important, but by using the properties.label as key it'll occur just once)
                        if (properties.required) {
                            if (value == '') {
                                result[properties.label] = value;
                            }
                        }
                        break;
                    case 'remember':
                        // This checks the properties 
                        if (properties.remember) {
                            result[el.name] = value;
                        }
                        break;
                    default:
                        console.error('Unknown type: ' + type);
                        break;
                }
            });
        } catch (error) {
            console.error(error);
            return false;
        }
        return result;
    }

    saveInfo(type, values, values_missing, values_show, remember = true, confirm = true, callback = null) {
        // values contains the name/value pairs that are to be checked and saved
        console.log('app.saveInfo(' + type + ',<values>,<values_missing>,<values_show>,' + remember + ',' + confirm + ',<callback>)');
        let self = this;

        try {
            // Prepare the record to store
            let uuid = self.generateUUID();
            if (values.uuid) {
                uuid = values.uuid;
            }
            let record = {
                id: uuid,
                type: type,
                createdAt: self.GMTToLocal(),
                status: 'local',
                data: values
            };

            // Remember the values, if specified and all required values have been provided
            if (remember && Object.keys(values_missing).length == 0) {
                self.rememberFieldValues(values);
            }

            // Prepare and show the modal only if confirm is true
            if (confirm) {
                // Prepare the modal
                document.getElementById('infoModalLabel').innerText = 'Er du sikker?';
                document.getElementById('infoModalCancel').hidden = false;

                // Show either the list of missing values, or the list of values
                let html = '';
                if (Object.keys(values_missing).length) {
                    for (let key in values_missing) {
                        html += (html ? ', ' : '') + self.escapeHTML(key).toLowerCase();
                    }
                    // Capitalize only the first character of the string
                    html = html[0].toUpperCase() + html.slice(1).toLowerCase();
                    document.getElementById('infoModalDescription').innerHTML = 'Vennligst oppgi følgende:<br>' + html + '.';

                    // Hide the OK button
                    document.getElementById('infoModalOK').hidden = true;
                } else {
                    for (let key in values_show) {
                        html += '<tr><td>' + self.escapeHTML(key) + '</td><td>' + self.escapeHTML(values_show[key] || '-') + '</td></tr>';
                    }
                    document.getElementById('infoModalDescription').innerHTML = '<table class="table-sm summary">' + html + '</table>';

                    // Show the OK button, with some code attached
                    document.getElementById('infoModalOK').hidden = false;
                    document.getElementById('infoModalOK').onclick = function () {
                        // Save the information, and emit an event that the database content has changed
                        self.storage.set('created.' + type + '.' + uuid, record, true);
                        self.popupInfoClose();
                        self.unloadForm(true);

                        // Do the callback, if set
                        if (callback) {
                            callback(uuid);
                        }
                    }
                }

                document.getElementById('infoModal')._modal.show();
            } else {
                // We're not showing anything, but saving directly
                self.storage.set('created.' + type + '.' + uuid, record, true);
                self.unloadForm(true);

                // Do the callback, if set
                if (callback) {
                    callback(uuid);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    popupInfoClose() {
        console.log('app.popupInfoClose()');

        try {
            document.getElementById('infoModal')._modal.hide();
        } catch (error) {
            console.error(error);
        }
    }

    download(info, progress) {
        console.log('app.download(<info>,<progress>)');
        let self = this;

        // eli = The element for the textual information, e.g. "Downloading 14/47"
        // elp = The progress bar element
        let eli = document.getElementById(info);
        let elp = document.getElementById(progress);

        function update(completed, total) {
            try {
                eli.innerText = 'Et øyeblikk, synkroniserer (' + completed + ' / ' + total + ')...';
                elp.style.width = Math.round(completed / total * 100) + '%';
            } catch (error) {
                console.error(error);
            }
        }

        function progressPromise(promises, tickCallback) {
            function tick(promise) {
                promise.then(function () {
                    progress++;
                    tickCallback(progress, len);
                });
                return promise;
            }

            var len = promises.length;
            var progress = 0;
            return Promise.all(promises.map(tick));
        }

        return new Promise(function (resolve, reject) {
            try {
                eli.innerText = 'Et øyeblikk, finner ut hva som skal synkroniseres...';
                elp.style.width = "0%";

                self.api.checkToken().then(() => {
                    // Do the initial fetches to decide what to download; these initial three fetches let us know what to download
                    let headers = new Headers({
                        'Authorization': 'Bearer ' + self.api.token
                    });
                    Promise.all([
                        fetch('https://' + self.api.server + self.api.server_path + 'info', { headers: headers }),
                        fetch('https://' + self.api.server + self.api.server_path + 'forms', { headers: headers }),
                        fetch('https://' + self.api.server + self.api.server_path + 'drawings', { headers: headers }),
                    ]).then((responses) => {
                        // Make sure we get a JSON object from each of the responses
                        return Promise.all(responses.map(function (response) {
                            return response.json();
                        }));
                    }).then((data) => {
                        // Make a list of tasks to carry out: get the blob keys and then decide what downloads to add to the task list
                        self.storage.blobs().then((idbKeys) => {
                            let tasks = [];
                            for (let key in data) {
                                for (let key2 in data[key].data.list) {
                                    if (data[key].data.type == 'drawings') {
                                        if (!idbKeys.includes('drawings.' + data[key].data.list[key2])) {
                                            tasks.push(fetch('https://' + self.api.server + self.api.server_path + data[key].data.type + '/' + data[key].data.list[key2], { headers: headers }));
                                        }
                                    } else {
                                        tasks.push(fetch('https://' + self.api.server + self.api.server_path + data[key].data.type + '/' + data[key].data.list[key2], { headers: headers }));
                                    }
                                }
                            }

                            // Add a specific task: forms information, projects, drawings
                            tasks.push(fetch('https://' + self.api.server + self.api.server_path + 'forms_info', { headers: headers }));
                            tasks.push(fetch('https://' + self.api.server + self.api.server_path + 'projects_assigned', { headers: headers }));
                            tasks.push(fetch('https://' + self.api.server + self.api.server_path + 'projects_drawings', { headers: headers }));

                            // Loop through the keys in indexedDB and remove those drawings that aren't in the download list
                            for (let idbKey in idbKeys) {
                                if (idbKeys[idbKey].indexOf('drawings') === 0) {
                                    let found = false;
                                    for (let key in data) {
                                        if (data[key].data.type == 'drawings') {
                                            for (let key2 in data[key].data.list) {
                                                if ('drawings.' + data[key].data.list[key2] == idbKeys[idbKey]) {
                                                    found = true;
                                                }
                                            }
                                        }
                                    }
                                    if (!found) {
                                        tasks.push(new Promise((resolve) => {
                                            window.application.storage.removeBlob(idbKeys[idbKey])
                                                .then(resolve('Removed blob ' + idbKeys[idbKey] + ' from indexedDB'));
                                        }));
                                    }
                                }
                            }

                            // Now that we have all tasks, carry them out
                            eli.innerText = 'Et øyeblikk, laster ned...';
                            progressPromise(tasks, update).then((responses) => {
                                // Make sure we get a JSON object from each of the responses
                                return Promise.all(responses.map(function (response) {
                                    if (response.headers) {
                                        if (response.headers.get('content-type') == 'application/json')
                                            return response.json().catch(error => {
                                                console.error(error);
                                                return null;
                                            });
                                    }
                                    return response;
                                }));
                            }).then(
                                (results) => {
                                    try {
                                        if (results) {
                                            for (let key in results) {
                                                if (results[key].data) {
                                                    switch (results[key].data.type) {
                                                        case 'info':
                                                            self.storage.set('info.' + results[key].data.name, results[key].data.content);
                                                            break;
                                                        case 'projects_drawings':
                                                            self.storage.set('info.' + results[key].data.name, results[key].data.content);
                                                            break;
                                                        case 'form':
                                                            self.storage.set('forms.' + results[key].data.name, results[key].data.content);
                                                            break;
                                                        case 'drawing':
                                                            self.storage.setBlob('drawings.' + results[key].data.name, self.storage.base64_to_blob(results[key].data.content));
                                                            break;
                                                        default:
                                                            // Not accounted for, yet
                                                            console.log('Missing handler for ' + results[key].data.type);
                                                            break;
                                                    }
                                                }
                                            }
                                        }
                                        self.storage.set('sync.downloaded', self.GMTToLocal().substring(0, 16));
                                        eli.innerText = 'Ferdig...';
                                        elp.style.width = "100%";
                                        resolve();
                                    } catch (error) {
                                        console.error(error);
                                        eli.innerText = 'Det oppsto en feil. Vennligst prøv igjen senere...';
                                        elp.style.width = "0%";
                                        reject();
                                    }
                                }
                            );
                        });
                    }).catch((error) => {
                        console.error(error);
                        eli.innerText = 'Det oppsto en feil. Vennligst prøv igjen senere...';
                        elp.style.width = "0%";
                        reject();
                    });
                }).catch((error) => {
                    console.error(error);
                    eli.innerText = 'Det oppsto en feil. Vennligst logg av og på, og prøv igjen...';
                    elp.style.width = "0%";
                    reject();
                });

            } catch (error) {
                console.error(error);
                eli.innerText = 'Det oppsto en feil. Vennligst prøv igjen senere...';
                elp.style.width = "0%";
                reject();
            }
        });
    }

    setPostStatus(status = 'local', key = null) {
        // Sets the status of all posts, usually to 'local' so they can be re-uploaded
        console.log('app.setPostStatus(' + status + ',' + key + ')');
        let self = this;

        let posts = self.storage.getAll('created.');
        for (let post in posts) {
            let update = true;
            if (key && posts[post].id !== key) {
                update = false;
            }
            if (update) {
                posts[post].status = status;
                self.storage.set('created.' + posts[post].type + '.' + posts[post].id, posts[post]);
            }
        }
        return true;
    }

    countElementsToUpload() {
        // Counts the number of elements to upload, both those in localStorage and the photos in indexedDB
        console.log('app.countElementsToUpload()');
        let self = this;
        let result = 0;

        // The info in localStorage
        let keys = self.storage.keys();
        for (let k in keys) {
            if (keys[k].substring(0, 8) == 'created.') {
                let post = self.storage.get(keys[k]);
                if (post.status == 'local') {
                    result++;
                }
            }
        }

        // The photos in indexedDB
        let photos = self.storage.get('app.pictures');
        for (let photo in photos) {
            if (photos[photo].status == 'local') {
                result++;
            }
        }

        return result;
    }

    uploadGetAsPromise(key, storageType = 'localstorage') {
        // This is a promise that picks up either the content of key in localStorage, or of a blob from indexeddb
        // It is called by upload(); because indexeddb requires promises, upload() needs to do a .then() anyway, so we'll wrap that for localStorage as well
        console.log('app.uploadGetAsPromise(' +key +',' +storageType +')');
        return new Promise(function (resolve, reject) {
            if (storageType == 'localstorage') {
                resolve(self.storage.get(key));
            } else {
                self.storage.getBlob(key).then((result) => {
                    try {
                        // Convert the result to base64
                        // This line causes sometimes a "Maximum call stack size exceeded" error:
                        // resolve( window.btoa( String.fromCharCode( ...new Uint8Array( result))));

                        // So instead use the following
                        // https://stackoverflow.com/a/42334410/4177565 - a comments from 2020-09-19
                        resolve( window.btoa(Array.from(new Uint8Array(result)).map(b => String.fromCharCode(b)).join('')));
                    } catch (error) {
                        reject(error);
                    }
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    }

    upload( callSelf = false ) {
        // This is run on app start and it will call itself unless callSelf == false, so it keeps repeating unless it crashes
        // For every time it runs, it sees if it needs to upload elements (in the foreground or background) and does so for 1 element
        // The timeout is controlled by:
        // * this.uploadTimerFast: when the upload button is clicked, or there's stuff to upload, we repeat quickly again
        // * this.uploadTimerSlow: when there is nothing to do, we give the cpu a longer break before running this again
        // Note: window.setTimeOut is erratic when the app is not in focus; that is just fine
        console.log('app.upload(' +(callSelf ? 'true':'false') +')');
        let self = this;

        // Update when this was last run - nice to know for other pieces of code (Sync.vue wants to know this)
        self.uploadLastRun = new Date();

        // If we're offline, exit rightaway
        if (!self.isOnline()) {
            if( callSelf ) {
                return window.setTimeout(() => {
                    self.upload( callSelf );
                }, self.uploadTimerSlow);
            } else {
                return null;
            }
        }

        // We'll find a key of a post that hasn't been uploaded yet, either in localstorage or indexeddb
        let key = null;
        let storagetype = null;

        let uploadAuto = self.storage.get('app.uploadAutomatic');
        let uploadManual = self.storage.get('app.uploadManual');
        if (uploadAuto || uploadManual) {
            // The user has autoUpload on, or is looking at the modal
            // So we will want to actually upload content, if possible and necessary, and then continue asap with the next upload()

            // Get any uploadable element
            let keys = self.storage.keys();
            for (let k in keys) {
                if (keys[k].substring(0, 8) == 'created.') {
                    // Inspect the status
                    let post = self.storage.get(keys[k]);
                    if (post.status == 'local') {
                        key = keys[k];
                        storagetype = 'localstorage';
                    }
                }
            }

            // If there are no posts to upload, check for pictures
            // The picture list is kept in localstorage with key "key.pictures"
            if (!key) {
                let pictures = self.storage.get('app.pictures');
                for (let picture in pictures) {
                    if (pictures[picture].status == 'local') {
                        key = pictures[picture].key;
                        storagetype = 'indexeddb';
                    }
                }

                // If no pictures were found with status 'local', check those with status 'failed'
                // These have been tried once, but we can try them again
                if(!key) {
                    for (let picture in pictures) {
                        if (pictures[picture].status == 'failed') {
                            key = pictures[picture].key;
                            storagetype = 'indexeddb';
                        }
                    }
                }
            }

            // If there is nothing to upload, set the progress bar as finished
            if (!key) {
                self.storage.set('app.uploadManual', false);
                self.storage.set('sync.uploaded', self.GMTToLocal().substring(0, 16));

                let eli = document.getElementById('upload_inform')
                if (eli) { eli.innerText = 'All informasjon er lastet opp.'; }
                let elp = document.getElementById('upload_progress');
                if (elp) { elp.style.width = '100%'; }
            }
        }

        if (key) {
            console.log( 'Attempting to upload ' +key +' from ' +storagetype );

            // Get the content
            self.uploadGetAsPromise(key, storagetype)
                .then((content) => {
                    // Images are base64, which is a string; posts are jsons which are objects
                    let method = 'post_info';
                    if (typeof content == 'string') {
                        method = 'post_image';
                    }

                    // Do the upload, and if all went well, inform, and update the record
                    self.api.doPost(method, key, content).then((result) => {
                        // If the result was successful
                        if (result) {
                            // Update the status so we know when it was last run
                            self.storage.set('sync.uploaded', self.GMTToLocal().substring(0, 16));
                            self.api.upload_done = self.api.upload_done + 1;

                            // Update the status of the post, or if this is a picture, of the entry in app.pictures
                            console.log('Updating status for ' + key);
                            if (storagetype == 'localstorage') {
                                content.status = 'uploaded';
                                self.storage.set(key, content);
                            } else {
                                let currentValues = self.storage.getFrom('app.pictures', key);
                                self.storage.setIn('app.pictures', key, {
                                    key: key,
                                    createdAt: currentValues.createdAt,
                                    status: 'uploaded'
                                });
                            }

                            // Update the progress bar, if available
                            let elp = document.getElementById('upload_progress');
                            if (elp) {
                                // Adjust the width based on the number elements uploaded vs those initially to be done
                                let width = Math.round(self.api.upload_done / self.api.upload_total * 100);
                                if (width > 100) {
                                    width = 100;
                                }
                                elp.style.width = width + '%';
                            } else {
                                // If the progress bar with modal is not available, let the screen be redrawn
                                window.dispatchEvent(new CustomEvent('storage-changed', {
                                    detail: {
                                        action: 'upload',
                                        key: key
                                    }
                                }));
                            }

                            // Call the function again, shortly
                            if( callSelf ) {
                                return window.setTimeout(() => {
                                    self.upload( callSelf );
                                }, self.uploadTimerFast);
                            } else {
                                return null;
                            }
                        } else {
                            console.error('Det oppsto en feil ved posting av data til tjeneren. Prøv å logge av og på.', result);
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                    if (key.substring(0, 7) == 'photos.') {
                        console.error('Photo ' + key + ' failed. Will retry later.');
                        let currentValues = self.storage.getFrom('app.pictures', key);
                        self.storage.setIn('app.pictures', key, {
                            key: key,
                            createdAt: currentValues.createdAt,
                            status: 'failed'
                        });
                    }

                    // Call the function again, shortly
                    if( callSelf ) {
                        return window.setTimeout(() => {
                            self.upload( callSelf );
                        }, self.uploadTimerFast);
                    } else {
                        return null;
                    }
                });
        } else {
            // This function runs periodically anyways and should continue to do so, but give the cpu a break of a few seconds
            // This is long enough so the CPU isn't bothered too much, yet short enough for the user to wait when clicking upload
            if( callSelf ) {
                return window.setTimeout(() => {
                    self.upload( callSelf );
                }, self.uploadTimerSlow);
            } else {
                return null;
            }
        }
    }

    cleanup(days = 90, localStorage = true, indexedDB = true) {
        // Cleans up already-uploaded elements if they are more than <days> days old
        console.log('app.cleanup(' + days + ',' + localStorage + ',' + indexedDB + ')');
        let cutoffDate = this.GMTToLocal(new Date(Date.now() - days * 24 * 60 * 60 * 1000));

        // Loop through the posts in localStorage
        let cntRemovedPosts = 0;
        if (localStorage) {
            let posts = this.storage.getAll('created.');
            for (let post of posts) {
                if (post.status == 'uploaded') {
                    if (post.createdAt < cutoffDate) {
                        this.storage.remove('created.' + post.type + '.' + post.id);
                        cntRemovedPosts++;
                    }
                }
            }
        }

        // Loop through the blobs in indexedDB (pictures)
        // Value app.pictures is set when taking a picture and updated when uploading
        let cntRemovedBlobs = 0;
        let promises = [];
        if (indexedDB) {
            let picture_list = self.storage.get('app.pictures');
            for (let el in picture_list) {
                if (picture_list[el].status == 'uploaded') {
                    if (picture_list[el].createdAt < cutoffDate) {
                        promises.push(window.application.storage.removeBlob(picture_list[el].key));
                    }
                }
            }
        }

        // Loop through the blobs in indexedDB (drawings)
        // Value app.drawings.obsolete is set and updated when downloading drawings
        if (indexedDB) {
            let drawing_list = self.storage.get('app.drawings.obsolete');
            for (let el in drawing_list) {
                promises.push(window.application.storage.removeBlob(drawing_list[el].key));
            }
        }

        let eli = document.getElementById('cleanup_inform');
        if (promises.length == 0) {
            if (cntRemovedPosts == 0) {
                eli.innerText = 'Ferdig.\nAppen trengte ikke rydde noe.';
            } else {
                eli.innerText = 'Ferdig.\nIngen bilder trengte bli ryddet, og ' + cntRemovedPosts + ' poster ble ryddet.';
            }
        } else {
            Promise
                .allSettled(promises)
                .then((result) => {
                    // Remove the pictures from the list (deleting them once is enough)
                    let picture_list = self.storage.get('app.pictures');
                    for (let el in picture_list) {
                        if (picture_list[el].createdAt < cutoffDate) {
                            self.storage.removeFrom('app.pictures', picture_list[el].key);
                        }
                    }

                    // Count the results and report
                    for (let result_el of result) {
                        if (result_el) {
                            cntRemovedBlobs++;
                        }
                    }
                    if (eli) {
                        if (cntRemovedBlobs == promises.length) {
                            if (cntRemovedPosts == 0 && cntRemovedBlobs == 0) {
                                eli.innerText = 'Ferdig.\nAppen trengte ikke rydde noe.';
                            } else if (cntRemovedPosts == 0 && cntRemovedBlobs > 0) {
                                eli.innerText = 'Ferdig.\nIngen poster ble ryddet, og ' + cntRemovedBlobs + ' bilder ble ryddet.';
                            } else if (cntRemovedPosts > 0 && cntRemovedBlobs == 0) {
                                eli.innerText = 'Ferdig.\nIngen bilder ble ryddet, og ' + cntRemovedPosts + ' poster ble ryddet.';
                            } else {
                                eli.innerText = 'Ferdig.\nAppen ryddet ' + cntRemovedPosts + ' poster og ' + cntRemovedBlobs + ' bilder.';
                            }
                        } else {
                            eli.innerText = 'Det oppsto en feil.\nKun ' + cntRemovedBlobs + ' av ' + promises.length + ' ble fjernet. Ta kontakt med support for å ordnet problemet med lagringen.';
                        }
                    }
                });
        }
    }

    capturePhoto(containerImageId = 'pictures', containerImageNameId = 'picture') {
        // Lets the user take a picture, add it to the post
        console.log('app.capturePhoto(' + containerImageId + ',' + containerImageNameId + ')');
        let self = this;

        self.camera.start('lastUsed', (key) => {
            // Store the image
            console.log( 'app.capturePhoto: image shot shot; key: ' +key );
            let self = window.application;

            // Update the list of images in the localstorage (for direct access)
            console.log( 'app.capturePhoto: caching file storage' );
            self.storage.setIn('app.pictures', key, {
                key: key,
                createdAt: window.application.GMTToLocal(),
                status: 'local'
            });

            // Add the image to the image container
            console.log( 'app.capturePhoto: adding ' +key +' to ' +containerImageId );
            let containerImage = document.getElementById(containerImageId);
            if (containerImage) {
                // Create a new image holder
                let newPicture = '<img class="picture" src="" id="' + key + '">';
                // todo: id picture hardcoded?
                console.log( 'app.capturePhoto: adding ' +newPicture +' to innerHTML' );
                document.getElementById('pictures').innerHTML += newPicture;

                // Have the application insert the image to it
                console.log( 'app.capturePhoto: loadBlob(' +key +',' +key +')' );
                self.storage.loadBlob(key, key);
            }

            // Add the key to the pictures field
            let containerImageName = document.getElementById(containerImageNameId);
            if (containerImageName) {
                let picValues = [];
                if (containerImageName.value) {
                    picValues = containerImageName.value.split(';');
                }
                picValues.push(key);
                containerImageName.value = picValues.join(';');
            }
        });
    }

    removePhoto(imageId, containerImageNameId = 'picture') {
        // Lets the user remove a picture
        console.log('app.removePhoto(' + imageId + ')');
        let self = this;

        self.confirm('Ønsker du slette dette bilde?', '', () => {
            // Remove it from the database and the DOM
            self.storage.removeBlob(imageId);
            document.getElementById(imageId).remove();

            // Also remove the text from the #picture element
            let containerImageName = document.getElementById(containerImageNameId);
            if (containerImageName) {
                let picValues = containerImageName.value.split(';');
                picValues = picValues.filter(arrayItem => arrayItem !== imageId);
                containerImageName.value = picValues.join(';');
            }

        });
    }

    restart() {
        console.log( 'app.restart()' );
        location.href = "/";
    }

    getOSAndBrowserInfo() {
        console.log( 'app.getOSAndBrowserInfo()' );
        let userAgent = navigator.userAgent;
        let platform = navigator.platform;
        let os = "Unknown OS";
        let browser = "Unknown Browser";
        let browserVersion = "Unknown Version";

        // Detect OS
        if (/Win/i.test(platform)) os = "Windows";
        else if (/Mac/i.test(platform)) os = "MacOS";
        else if (/Linux/i.test(platform)) os = "Linux";
        else if (/Android/i.test(userAgent)) os = "Android";
        else if (/iPhone|iPad|iPod/i.test(userAgent)) os = "iOS";

        // Detect Browser
        if (/Edg\/(\d+)/i.test(userAgent)) {
            browser = "Edge";
            browserVersion = userAgent.match(/Edg\/(\d+)/i)[1];
        } else if (/Chrome\/(\d+)/i.test(userAgent) && !/Edg/i.test(userAgent)) {
            browser = "Chrome";
            browserVersion = userAgent.match(/Chrome\/(\d+)/i)[1];
        } else if (/Firefox\/(\d+)/i.test(userAgent)) {
            browser = "Firefox";
            browserVersion = userAgent.match(/Firefox\/(\d+)/i)[1];
        } else if (/Safari\/(\d+)/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
            browser = "Safari";
            browserVersion = userAgent.match(/Version\/(\d+)/i) ? userAgent.match(/Version\/(\d+)/i)[1] : "Unknown";
        } else if (/MSIE (\d+)/i.test(userAgent) || /Trident\/.*rv:(\d+)/i.test(userAgent)) {
            browser = "Internet Explorer";
            browserVersion = userAgent.match(/MSIE (\d+)/i) ? userAgent.match(/MSIE (\d+)/i)[1] : userAgent.match(/rv:(\d+)/i)[1];
        }

        return { os, browser, browserVersion };
    }

    forceUpdateAndRestart() {
        console.log( 'app.forceUpdateAndRestart()' );
        navigator.serviceWorker.getRegistration().then(function (reg) {
            if (reg) {
                reg.update().then(function () {
                    window.location = '/';
                });
            }
        });
    }
}